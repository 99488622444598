import * as React from "react";
import { useMemo } from "react";
import { graphql, HeadFC, PageProps } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Articles } from "../components/articles";
import { blogPosts } from "../utils/data";
import { Col, Container, Row } from "react-bootstrap";

const IndexPage: React.FC<PageProps> = ({ data }) => {
    console.log(data);
    const articles = useMemo(() => blogPosts.convert(data, "blog").slice(0, 8), [data]);
    const products = useMemo(() => blogPosts.convert(data, "product").slice(0, 8), [data]);

    return (
        <Layout activeKey={"/"}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h1>Real Smart Home - Your Gateway to Modern Living</h1>
                            <p className="lead">
                                Welcome to <strong>Real Smart Home</strong>, your one-stop
                                destination for everything related to{" "}
                                <strong>smart home technology</strong>. Whether you're looking to
                                explore the latest trends in <strong>home automation</strong>, or
                                you're on the hunt for the best <strong>smart home products</strong>
                                , you've come to the right place. Our blog and product sections are
                                designed to help you make your home more connected, secure, and
                                energy-efficient with cutting-edge{" "}
                                <strong>smart home systems</strong>. Dive into the world of{" "}
                                <strong>voice control</strong>, <strong>smart security</strong>, and
                                more, as we guide you through the latest innovations that are
                                transforming homes across the globe.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Explore Our Blog - Your Source for Smart Home Insights</h2>
                            <p className="lead">
                                At Real Smart Home, we believe that knowledge is
                                key to making the most out of your{" "}
                                smart home technology. Our blog is packed with
                                insightful articles, tips, and guides to help you navigate the world
                                of smart home devices. Whether you're a beginner
                                just getting started with home automation, or a
                                seasoned pro looking for the latest updates, our blog has something
                                for everyone.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Articles articles={articles} />
            <Container>
                <Row>
                    <Col xs={12} sm={6} md={4}>
                        <div className={"mainheading"}>
                            <h2>Discover the Latest in Smart Home Technology</h2>
                            <p className="lead">
                                Stay up-to-date with the latest advancements in{" "}
                                smart home technology. From{" "}
                                <strong>Google Home</strong> to the newest{" "}
                                <strong>Google Nest</strong> devices, our blog covers the most
                                innovative products that are revolutionizing the way we live. Learn
                                how to integrate these devices into your{" "}
                                <strong>smart system</strong> and make your home smarter, safer, and
                                more energy-efficient.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className={"mainheading"}>
                            <h2>Master Voice Control and Automation</h2>
                            <p className="lead">
                                {" "}
                                <strong>Voice commands</strong> are becoming an essential part of
                                modern home automation. With devices like{" "}
                                Google Home and other voice-activated{" "}
                                smart home systems, you can control your{" "}
                                <strong>home temperature</strong>, <strong>smart lights</strong>,
                                and even your <strong>security system</strong> with just your voice.
                                Our blog provides detailed guides on setting up and optimizing{" "}
                                voice control in your home, ensuring that your{" "}
                                smart home devices respond accurately and
                                efficiently to your commands.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className={"mainheading"}>
                            <h2>Enhance Your Home Security</h2>
                            <p className="lead">
                                {" "}
                                <strong>Security</strong> is a top priority for any homeowner, and{" "}
                                smart security systems are making it easier than
                                ever to protect your home. From <strong>smart locks</strong> and{" "}
                                <strong>door locks</strong> that you can{" "}
                                <strong>remotely control</strong>, to advanced{" "}
                                <strong>security systems</strong> that integrate with your{" "}
                                <strong>Wi-Fi</strong>, our blog covers the best{" "}
                                smart security products available. Learn how to set
                                up a comprehensive security system that keeps your
                                home safe, whether you're at home or away.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Transform Your Home with Smart Home Products</h2>
                            <p className="lead">
                                In addition to our blog, Real Smart Home offers a
                                wide range of smart home products designed to meet
                                your every need. Whether you're looking to upgrade your{" "}
                                <strong>smart lighting</strong>, install a{" "}
                                <strong>smart thermostat</strong> to control your{" "}
                                home temperature, or invest in a full-fledged{" "}
                                <strong>smart home system</strong>, our product section has it all.
                                Browse our selection of the latest{" "}
                                smart home devices and find the perfect products to
                                enhance your living space.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Articles articles={products} />
            <Container>
                <Row>
                    <Col xs={12} sm={6}>
                        <div className={"mainheading"}>
                            <h2>Why Choose Real Smart Home?</h2>
                            <p className="lead">
                                At Real Smart Home, we are committed to helping you
                                create a home that is not only smart but also tailored to your
                                lifestyle. Our extensive knowledge of{" "}
                                smart home technology, combined with our carefully
                                curated selection of products, ensures that you have access to the
                                best solutions on the market. Whether you're looking to implement a
                                complete <strong>home automation system</strong> or just add a few{" "}
                                <strong>smart devices</strong> to your setup, we are here to guide
                                you every step of the way.
                            </p>
                            <ul>
                                <li>Extensive knowledge of smart home technology</li>
                                <li>Carefully curated selection of products</li>
                                <li>Guidance on complete home automation systems</li>
                                <li>Support for adding smart devices to your setup</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>
                        <div className={"mainheading"}>
                            <h2>Start Your Smart Home Journey Today</h2>
                            <p className="lead">
                                Ready to take the first step towards a smarter home? Explore our
                                blog for in-depth articles and expert advice, and visit our product
                                section to discover the latest in{" "}
                                smart home technology. With{" "}
                                Real Smart Home, the future of living is just a
                                click away.
                            </p>
                            <p className="lead">
                                Thank you for choosing Real Smart Home. We look
                                forward to helping you make your home smarter, safer, and more
                                connected.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export const query = graphql`
    query IndexPage {
        allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;

export const Head: HeadFC = () => (
    <Seo
        url={"/"}
        title="Real Smart Home - newses from home automation world"
        shortTitle
        description={
            "Explore smart home automation trends, tips, and reviews to enhance your living space with improved comfort, security, and efficiency."
        }
    />
);
